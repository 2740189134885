<template>
  <div class="scale-100 tall:scale-140">
    <div class="flex items-center" :class="isStopped ? 'animate-to-scale' : ''">
      <div
        v-bind="$attrs"
        class="relative flex justify-center items-center w-64 h-64"
        :class="isStopped ? 'mt-8' : ''"
      >
        <div
          class="absolute inset-6 rounded-full overflow-hidden z-10 bg-jm_white bg-center bg-cover"
          :class="
            isStopped || globalStore.timesLeftToPlay === 500
              ? 'bg-sun scale-[150%] -translate-y-[3rem] -translate-x-[-.6rem]'
              : ''
          "
          :style="{
            backgroundImage:
              isStopped || globalStore.timesLeftToPlay === 0
                ? ''
                : `url(${require('@/assets/images/backgrounds/bg-shot.svg')})`,
          }"
        >
          <span
            v-if="!currentImage"
            class="w-full h-full items-center justify-center align-middle text-center flex flex-col p-6 text-jm_green"
          >
            <div class="font-bold leading-tight">
              {{ $t("image_slider.loading") }}
            </div>
            <div class="leading-tight">
              {{ $t("image_slider.please_wait") }}
            </div>
          </span>
          <img
            :src="currentImage"
            class="w-full h-full object-cover transition-opacity duration-100"
            v-if="globalStore.timesLeftToPlay !== 0"
          />
          <span
            v-else
            class="w-full h-full items-center justify-center align-middle text-center flex flex-col p-6 text-jm_green"
          >
            <div class="font-bold leading-tight">
              {{ $t("image_slider.thats_enough") }}
            </div>
            <div class="leading-tight">
              {{ $t("image_slider.thanks_for_playing") }}
            </div>
          </span>
        </div>
        <div
          class="absolute inset-0 w-full h-full animate-spin"
          v-if="!isStopped"
        >
          <img
            :src="require('@/assets/images/misc/circular.svg')"
            class="w-full h-full object-cover"
          />
        </div>

        <div class="absolute inset-0">
          <svg
            class="w-full h-full"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            :class="isStopped ? '-translate-x-[-.6rem]' : ''"
          >
            <defs>
              <path
                id="circlePath"
                fill="none"
                stroke="none"
                d="
              M 100, 100
              m -90, 0
              a 90,90 0 1,1 180,0
              a 90,90 0 1,1 -180,0
            "
              />
            </defs>
            <circle
              v-if="isStopped"
              cx="100"
              cy="68"
              r="135"
              fill="none"
              stroke-width="8"
              class="transition-all duration-10 ease-in-out"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center" v-if="globalStore.timesLeftToPlay !== 0">
      <ActionDescription
        :current-header="currentHeader"
        :current-subheader="currentSubheader"
        :is-stopped="isStopped"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import EventBus from "@/plugins/EventBus";
import imageTextData from "@/assets/data/imageAndTextData.json";
import ActionDescription from "./ActionDescription.vue";
import CloseButton from "@/components/CloseButton.vue";
import { useGlobalStore } from "../stores/global";
import i18n from "@/plugins/i18n";
import { setItem, getItem, clearIndexedDB } from "@/utils/indexedDB";

import img01 from "@/assets/images/game/01.png";
import img02 from "@/assets/images/game/02.png";
import img03 from "@/assets/images/game/03.png";
import img04 from "@/assets/images/game/04.png";
import img05 from "@/assets/images/game/05.png";
import img06 from "@/assets/images/game/06.png";
import img07 from "@/assets/images/game/07.png";
import img08 from "@/assets/images/game/08.png";
import img09 from "@/assets/images/game/09.png";
import img10 from "@/assets/images/game/10.png";
import img11 from "@/assets/images/game/11.png";
import img12 from "@/assets/images/game/12.png";
import img13 from "@/assets/images/game/13.png";
import img14 from "@/assets/images/game/14.png";
import img15 from "@/assets/images/game/15.png";
import img16 from "@/assets/images/game/16.png";
import img17 from "@/assets/images/game/17.png";
import img18 from "@/assets/images/game/18.png";
import img19 from "@/assets/images/game/19.png";
import img20 from "@/assets/images/game/20.png";
import img21 from "@/assets/images/game/21.png";
import img22 from "@/assets/images/game/22.png";
import img23 from "@/assets/images/game/23.png";
import img24 from "@/assets/images/game/24.png";
import img25 from "@/assets/images/game/25.png";
import img26 from "@/assets/images/game/26.png";
import img27 from "@/assets/images/game/27.png";
import img28 from "@/assets/images/game/28.png";
import img29 from "@/assets/images/game/29.png";
import img30 from "@/assets/images/game/30.png";
import img31 from "@/assets/images/game/31.png";
import img32 from "@/assets/images/game/32.png";
import img33 from "@/assets/images/game/33.png";
import img34 from "@/assets/images/game/34.png";
import img35 from "@/assets/images/game/35.png";
import img36 from "@/assets/images/game/36.png";
import img37 from "@/assets/images/game/37.png";
import img38 from "@/assets/images/game/38.png";
import img39 from "@/assets/images/game/39.png";
import img40 from "@/assets/images/game/40.png";
import img41 from "@/assets/images/game/41.png";
import img42 from "@/assets/images/game/42.png";
import img43 from "@/assets/images/game/43.png";
import img44 from "@/assets/images/game/44.png";
import img45 from "@/assets/images/game/45.png";
import img46 from "@/assets/images/game/46.png";
import img47 from "@/assets/images/game/47.png";
import img48 from "@/assets/images/game/48.png";
import img49 from "@/assets/images/game/49.png";
import img50 from "@/assets/images/game/50.png";
import img51 from "@/assets/images/game/51.png";
import img52 from "@/assets/images/game/52.png";
import img53 from "@/assets/images/game/53.png";
import img54 from "@/assets/images/game/54.png";
import img55 from "@/assets/images/game/55.png";
import img56 from "@/assets/images/game/56.png";

const globalStore = useGlobalStore();

const images = ref([
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
]);

const currentImage = ref("");
let isStopped = ref(false);

const loadImage = async (imageName: string) => {
  if (globalStore.timesLeftToPlay === 0) return "";
  try {
    const cachedImageBlob = await getItem("images", imageName);

    if (cachedImageBlob) {
      return URL.createObjectURL(cachedImageBlob);
    } else {
      const response = await fetch(imageName);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${imageName}`);
      }
      const blob = await response.blob();
      await setItem("images", imageName, blob);
      return URL.createObjectURL(blob);
    }
  } catch (error) {
    console.error("Error loading image:", error);
    return "";
  }
};

let currentImageIdx = ref(0);

const changeImage = async () => {
  if (isStopped.value || globalStore.timesLeftToPlay === 0) return;
  const randomIndex = Math.floor(Math.random() * images.value.length);
  currentImageIdx.value = randomIndex;

  const imageName = images.value[randomIndex];
  currentImage.value = await loadImage(imageName);
};

const currentImageIndex = computed(() => {
  return currentImageIdx.value + 1;
});

const currentHeader = computed(() => {
  const indexKey = String(currentImageIndex.value).padStart(2, "0");
  const imageData = imageTextData[i18n.global.locale][indexKey];
  return imageData && imageData.header ? imageData.header : "...";
});

const currentSubheader = computed(() => {
  const indexKey = String(currentImageIndex.value).padStart(2, "0");
  const imageData = imageTextData[i18n.global.locale][indexKey];
  return imageData && imageData.subheader ? imageData.subheader : "...";
});
const selectCurrentImage = () => {
  isStopped.value = true;
};

const preloadImages = async () => {
  if (globalStore.timesLeftToPlay === 0) return;
  console.log("Preloading images...");
  const preloadPromises = images.value.map(async (imageUrl: string) => {
    console.log("Preloading:", imageUrl);
    await loadImage(imageUrl);
  });

  await Promise.all(preloadPromises);
};

onMounted(async () => {
  await clearIndexedDB();
  await preloadImages();
  setInterval(changeImage, 130);
});

EventBus.on("startGame", (value: boolean) => {
  isStopped.value = value;
  selectCurrentImage;
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.relative {
  position: relative;
  width: 300px;
  height: 300px;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
}

circle {
  fill: none;
  stroke: $jm_orange;
  transition: r 0.1s ease, stroke-width 0.5s ease;
}

.text,
.rotate {
  animation: rotate 15s linear infinite;
  text-anchor: middle;
  dominant-baseline: middle;
  transform-origin: center;
}

button {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.bg-sun::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: rotate 15s linear infinite;
  background-image: url(@/assets/images/backgrounds/bg-shot.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.animate-to-scale {
  animation: scale 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes scale {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

img {
  transition: opacity 1s ease-in-out;
}
</style>
