<template>
  <DefaultLayout background-image="green">
    <div class="flex h-full flex-col items-center justify-between mb-10">
      <div>
        <img
          :src="logo"
          alt="logo"
          class="h-20 tall:h-32 tall:mt-12 m-4"
          :class="showingLogo ? '' : 'opacity-0'"
        />
      </div>
      <div class="flex flex-col items-center">
        <ImageSlider />
      </div>
      <div class="mt-2 tall:mb-12">
        <PushButton @isStopped="showLogo" />
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import PushButton from "@/components/PushButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import ImageSlider from "@/components/ImageSlider.vue";
import logo from "@/assets/images/misc/logo.svg";
import { ref } from "vue";

const showingLogo = ref(true);

const showLogo = (isStopped: boolean) => {
  showingLogo.value = !isStopped;
};
</script>
