import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "scale-100 tall:scale-140" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full items-center justify-center align-middle text-center flex flex-col p-6 text-jm_green"
}
const _hoisted_3 = { class: "font-bold leading-tight" }
const _hoisted_4 = { class: "leading-tight" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "w-full h-full items-center justify-center align-middle text-center flex flex-col p-6 text-jm_green"
}
const _hoisted_7 = { class: "font-bold leading-tight" }
const _hoisted_8 = { class: "leading-tight" }
const _hoisted_9 = {
  key: 0,
  class: "absolute inset-0 w-full h-full animate-spin"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "absolute inset-0" }
const _hoisted_12 = {
  key: 0,
  cx: "100",
  cy: "68",
  r: "135",
  fill: "none",
  "stroke-width": "8",
  class: "transition-all duration-10 ease-in-out"
}
const _hoisted_13 = {
  key: 0,
  class: "mt-4 text-center"
}

import { ref, onMounted, computed } from "vue";
import EventBus from "@/plugins/EventBus";
import imageTextData from "@/assets/data/imageAndTextData.json";
import ActionDescription from "./ActionDescription.vue";
import CloseButton from "@/components/CloseButton.vue";
import { useGlobalStore } from "../stores/global";
import i18n from "@/plugins/i18n";
import { setItem, getItem, clearIndexedDB } from "@/utils/indexedDB";

import img01 from "@/assets/images/game/01.png";
import img02 from "@/assets/images/game/02.png";
import img03 from "@/assets/images/game/03.png";
import img04 from "@/assets/images/game/04.png";
import img05 from "@/assets/images/game/05.png";
import img06 from "@/assets/images/game/06.png";
import img07 from "@/assets/images/game/07.png";
import img08 from "@/assets/images/game/08.png";
import img09 from "@/assets/images/game/09.png";
import img10 from "@/assets/images/game/10.png";
import img11 from "@/assets/images/game/11.png";
import img12 from "@/assets/images/game/12.png";
import img13 from "@/assets/images/game/13.png";
import img14 from "@/assets/images/game/14.png";
import img15 from "@/assets/images/game/15.png";
import img16 from "@/assets/images/game/16.png";
import img17 from "@/assets/images/game/17.png";
import img18 from "@/assets/images/game/18.png";
import img19 from "@/assets/images/game/19.png";
import img20 from "@/assets/images/game/20.png";
import img21 from "@/assets/images/game/21.png";
import img22 from "@/assets/images/game/22.png";
import img23 from "@/assets/images/game/23.png";
import img24 from "@/assets/images/game/24.png";
import img25 from "@/assets/images/game/25.png";
import img26 from "@/assets/images/game/26.png";
import img27 from "@/assets/images/game/27.png";
import img28 from "@/assets/images/game/28.png";
import img29 from "@/assets/images/game/29.png";
import img30 from "@/assets/images/game/30.png";
import img31 from "@/assets/images/game/31.png";
import img32 from "@/assets/images/game/32.png";
import img33 from "@/assets/images/game/33.png";
import img34 from "@/assets/images/game/34.png";
import img35 from "@/assets/images/game/35.png";
import img36 from "@/assets/images/game/36.png";
import img37 from "@/assets/images/game/37.png";
import img38 from "@/assets/images/game/38.png";
import img39 from "@/assets/images/game/39.png";
import img40 from "@/assets/images/game/40.png";
import img41 from "@/assets/images/game/41.png";
import img42 from "@/assets/images/game/42.png";
import img43 from "@/assets/images/game/43.png";
import img44 from "@/assets/images/game/44.png";
import img45 from "@/assets/images/game/45.png";
import img46 from "@/assets/images/game/46.png";
import img47 from "@/assets/images/game/47.png";
import img48 from "@/assets/images/game/48.png";
import img49 from "@/assets/images/game/49.png";
import img50 from "@/assets/images/game/50.png";
import img51 from "@/assets/images/game/51.png";
import img52 from "@/assets/images/game/52.png";
import img53 from "@/assets/images/game/53.png";
import img54 from "@/assets/images/game/54.png";
import img55 from "@/assets/images/game/55.png";
import img56 from "@/assets/images/game/56.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageSlider',
  setup(__props) {

const globalStore = useGlobalStore();

const images = ref([
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
]);

const currentImage = ref("");
let isStopped = ref(false);

const loadImage = async (imageName: string) => {
  if (globalStore.timesLeftToPlay === 0) return "";
  try {
    const cachedImageBlob = await getItem("images", imageName);

    if (cachedImageBlob) {
      return URL.createObjectURL(cachedImageBlob);
    } else {
      const response = await fetch(imageName);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${imageName}`);
      }
      const blob = await response.blob();
      await setItem("images", imageName, blob);
      return URL.createObjectURL(blob);
    }
  } catch (error) {
    console.error("Error loading image:", error);
    return "";
  }
};

let currentImageIdx = ref(0);

const changeImage = async () => {
  if (isStopped.value || globalStore.timesLeftToPlay === 0) return;
  const randomIndex = Math.floor(Math.random() * images.value.length);
  currentImageIdx.value = randomIndex;

  const imageName = images.value[randomIndex];
  currentImage.value = await loadImage(imageName);
};

const currentImageIndex = computed(() => {
  return currentImageIdx.value + 1;
});

const currentHeader = computed(() => {
  const indexKey = String(currentImageIndex.value).padStart(2, "0");
  const imageData = imageTextData[i18n.global.locale][indexKey];
  return imageData && imageData.header ? imageData.header : "...";
});

const currentSubheader = computed(() => {
  const indexKey = String(currentImageIndex.value).padStart(2, "0");
  const imageData = imageTextData[i18n.global.locale][indexKey];
  return imageData && imageData.subheader ? imageData.subheader : "...";
});
const selectCurrentImage = () => {
  isStopped.value = true;
};

const preloadImages = async () => {
  if (globalStore.timesLeftToPlay === 0) return;
  console.log("Preloading images...");
  const preloadPromises = images.value.map(async (imageUrl: string) => {
    console.log("Preloading:", imageUrl);
    await loadImage(imageUrl);
  });

  await Promise.all(preloadPromises);
};

onMounted(async () => {
  await clearIndexedDB();
  await preloadImages();
  setInterval(changeImage, 130);
});

EventBus.on("startGame", (value: boolean) => {
  isStopped.value = value;
  selectCurrentImage;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center", _unref(isStopped) ? 'animate-to-scale' : ''])
    }, [
      _createElementVNode("div", _mergeProps(_ctx.$attrs, {
        class: ["relative flex justify-center items-center w-64 h-64", _unref(isStopped) ? 'mt-8' : '']
      }), [
        _createElementVNode("div", {
          class: _normalizeClass(["absolute inset-6 rounded-full overflow-hidden z-10 bg-jm_white bg-center bg-cover", 
            _unref(isStopped) || _unref(globalStore).timesLeftToPlay === 500
              ? 'bg-sun scale-[150%] -translate-y-[3rem] -translate-x-[-.6rem]'
              : ''
          ]),
          style: _normalizeStyle({
            backgroundImage:
              _unref(isStopped) || _unref(globalStore).timesLeftToPlay === 0
                ? ''
                : `url(${require('@/assets/images/backgrounds/bg-shot.svg')})`,
          })
        }, [
          (!currentImage.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("image_slider.loading")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("image_slider.please_wait")), 1)
              ]))
            : _createCommentVNode("", true),
          (_unref(globalStore).timesLeftToPlay !== 0)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: currentImage.value,
                class: "w-full h-full object-cover transition-opacity duration-100"
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("image_slider.thats_enough")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("image_slider.thanks_for_playing")), 1)
              ]))
        ], 6),
        (!_unref(isStopped))
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                src: require('@/assets/images/misc/circular.svg'),
                class: "w-full h-full object-cover"
              }, null, 8, _hoisted_10)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(["w-full h-full", _unref(isStopped) ? '-translate-x-[-.6rem]' : '']),
            viewBox: "0 0 200 200",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _cache[0] || (_cache[0] = _createElementVNode("defs", null, [
              _createElementVNode("path", {
                id: "circlePath",
                fill: "none",
                stroke: "none",
                d: "\n              M 100, 100\n              m -90, 0\n              a 90,90 0 1,1 180,0\n              a 90,90 0 1,1 -180,0\n            "
              })
            ], -1)),
            (_unref(isStopped))
              ? (_openBlock(), _createElementBlock("circle", _hoisted_12))
              : _createCommentVNode("", true)
          ], 2))
        ])
      ], 16)
    ], 2),
    (_unref(globalStore).timesLeftToPlay !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(ActionDescription, {
            "current-header": currentHeader.value,
            "current-subheader": currentSubheader.value,
            "is-stopped": _unref(isStopped)
          }, null, 8, ["current-header", "current-subheader", "is-stopped"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})